<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>
          Live Jobs
          <span class="float-right">
            Total value: 
            {{ total | priceInPounds | currency }}
          </span>
        </h1>
        <p>This is a list of all jobs which have been converted, including not yet planned, planned, completed awaiting approval and completed awaiting invoice.</p>
      </v-col>
    </v-row>
    <v-form @submit.prevent="getJobs">
      <v-row class="pt-8">
        <v-col class="col">
          <label>Job No</label>
          <v-text-field v-model="jobNumber"></v-text-field>
        </v-col>
        <v-col class="col">
          <label>Customer</label>
          <v-text-field v-model="customer"></v-text-field>
        </v-col>
        <v-col class="col">
          <label>Sales person</label>
          <v-text-field v-model="salesPerson"></v-text-field>
        </v-col>
        <v-col class="col">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <label class="pl-8">Date</label>
              <v-text-field
                v-model="date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              range>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col class="col-3 pt-10">
          <v-btn type="sumbit">Search</v-btn>
          &nbsp;
          <v-btn @click="clearSearch">Clear</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Job No</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Date</th>
                <th class="text-left">Site</th>
                <th class="text-left">Value</th>
                <th class="text-left">By</th>
                <th class="text-left">Status</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="job in jobs"
                :key="job.id"
              >
                <td>{{ job.jobNumber }}</td>
                <td>{{ job.customer }}</td>
                <td>{{ job.date | tinyDate }}</td>
                <td>{{ job.site }}</td>
                <td>
                  <span v-if="job.value > job.minChargeAmount">
                    {{ job.value | priceInPounds | currency }}
                  </span>
                  <span v-else>
                    {{ job.minChargeAmount | priceInPounds | currency }}
                  </span>
                </td>
                <td>{{ job.quotedByInitials }}</td>
                <td>
                  <span v-if="job.status === 0">Not planned</span>
                  <span v-if="job.status === 1">Planned</span>
                  <span v-if="job.status === 2">Complete</span>
                  <span v-if="job.status === 3">Authorised</span>
                </td>
                <td class="pr-0">
                  <router-link custom v-slot="{ navigate }" :to="`/live-jobs/overview/${job.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'LiveJobsIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      jobs: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      total: 0,
      date: [],
      dateDialogue: false,
      modal: false,
      customer: '',
      salesPerson: '',
      jobNumber: '',
    };
  },
  watch: {
    page() {
      this.getJobs();
    },
  },
  methods: {
    clearSearch() {
      this.jobNumber = '';
      this.customer = '';
      this.salesPerson = '';
      this.date = [];
      this.getJobs(); 
    },
    getJobs() {
      const postData = {};
      postData.date = this.date;
      postData.customer = this.customer;
      postData.salesPerson = this.salesPerson;
      postData.jobNumber = this.jobNumber;
      axios.post(`/liveJobs/getAll/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.jobs = response.data.jobs;
          this.length = response.data.length;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getJobs();
  },
};
</script>
